import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '../css/Component/showPdf.css';

const ShowPdf = ({ source }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    // Augmentez le zoom initial pour que le fichier soit plus zoomé d'origine
    const scale = isMobile ? 0.8 : 1.3;

    return (
        <div className='pdf-container'>
            <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                <Viewer 
                    fileUrl={source}
                    plugins={isMobile ? [] : [defaultLayoutPluginInstance]}  // Désactive le layout sur mobile
                    defaultScale={scale}
                    style={{ height: '100%', width: '100%' }} // S'assure que le Viewer prend toute la hauteur et la largeur
                />
            </Worker>
        </div>
    );
};

export default ShowPdf;
