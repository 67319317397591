import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../css/View/Menu.css';
import Profil from './Profil.js';
import Fiche from './Fiche.js';
import Formateur from './Formateur.js';
import Admin from './Admin.js';
import QuizQuotidien from './QuizQuotidien.js';
import Formation from './Formation.js';
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import { disconectUser } from '../routes/routes';
import { getTokenFCM } from '../script/notif';
import { isAdmin, isFormateur, hasKey } from '../routes/routes.js';
import Chargement from '../components/chargement.js';

const Menu = () => {
  const [activePage, setActivePage] = useState('Quiz quotidien');
  const [admin, setAdmin] = useState(false);
  const [formateur, setFormateur] = useState(false);
  const [hasUserKey, setHasUserKey] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserRolesAndKey = async () => {
      try {
        const user = auth.currentUser;

        if (user) {
          const [isAdminUser, isFormateurUser, userHasKey] = await Promise.all([
            isAdmin(),
            isFormateur(),
            hasKey()
          ]);
          
          setAdmin(isAdminUser);
          setFormateur(isFormateurUser);
          setHasUserKey(userHasKey);
        }
      } catch (error) {
        console.error('Error checking roles or key:', error);
      } finally {
        setLoading(false); 
      }
    };

    // Attendre que l'utilisateur soit authentifié avant de vérifier les rôles et la clé
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkUserRolesAndKey();
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkData = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        const [isAdminUser, isFormateurUser, userHasKey] = await Promise.all([
          isAdmin(),
          isFormateur(),
          hasKey()
        ]);
        
        setAdmin(isAdminUser);
        setFormateur(isFormateurUser);
        setHasUserKey(userHasKey);
      }
    } catch (error) {
      console.error('Error checking roles or key:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      const tokenFCM = await getTokenFCM();
  
      if (tokenFCM) {
        await disconectUser(tokenFCM);
      }
      localStorage.removeItem('isAuthenticated');
      await signOut(auth);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const renderActivePage = () => {
    switch (activePage) {
      case 'Quiz quotidien':
        return <QuizQuotidien />;
      case 'Formation':
        return <Formation />;
      case 'Fiche Réflexe':
        return <Fiche />;
      case 'Profil':
        return <Profil profilChange={checkData}/>;
      case 'Formateur':
        return <Formateur />;
      case 'Admin':
        return <Admin />;
      default:
        return <div>Sélectionnez une option dans le menu.</div>;
    }
  };

  if (loading) {
    return <div className="loading"><Chargement /></div>;
  }

  return (
    <div className="menu-container">
      <div className="menu">
        {hasUserKey ? (
          <>
            <div className="menu-icon" onClick={() => setActivePage('Quiz quotidien')}>
              <i className="fas fa-question-circle"></i>
              <span>Quiz quotidien</span>
            </div>
            <div className="menu-icon" onClick={() => setActivePage('Formation')}>
              <i className="fas fa-graduation-cap"></i>
              <span>Formation</span>
            </div>
            <div className="menu-icon" onClick={() => setActivePage('Fiche Réflexe')}>
              <i className="fas fa-file-alt"></i>
              <span>Fiche Réflexe</span>
            </div>
            <div className="menu-icon" onClick={() => setActivePage('Profil')}>
              <i className="fas fa-user"></i>
              <span>Profil</span>
            </div>
            {formateur && (
              <div className="menu-icon formateur-icon" onClick={() => setActivePage('Formateur')}>
                <i className="fas fa-briefcase"></i>
                <span>Formateur</span>
              </div>
            )}
            {admin && (
              <div className="menu-icon admin-icon" onClick={() => setActivePage('Admin')}>
                <i className="fas fa-code"></i>
                <span>Admin</span>
              </div>
            )}
          </>
        ) : (
          <div className="menu-icon" onClick={() => setActivePage('Profil')}>
            <i className="fas fa-user"></i>
            <span>Profil</span>
          </div>
        )}
        <div className="menu-icon logout-icon" onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
          <span>Déconnexion</span>
        </div>
      </div>
      <div className="content">
        {renderActivePage()}
      </div>
    </div>
  );
}

export default Menu;
