import React, { useState, useEffect } from 'react';
import ShowPdf from '../components/showPdf';
import { getFicheArbo } from '../routes/routes.js'; // Importez la fonction de requête
import Chargement from '../components/chargement.js';
import '../css/View/Fiche.css';

const Fiche = () => {
    const [treeData, setTreeData] = useState(null); // Remplacez l'objet statique par un état
    const [currentPath, setCurrentPath] = useState([]);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchTreeData = async () => {
            const data = await getFicheArbo(); // Appel de la fonction pour récupérer l'arborescence
            if (data) {
                setTreeData(data);
            }
        };

        fetchTreeData(); // Exécution de la requête au montage du composant
    }, []);

    // Fonction pour naviguer dans l'arborescence
    const navigateTo = (path) => {
        setCurrentPath(path);
        setSelectedPdf(null);  // Réinitialise la sélection du PDF
    };

    // Fonction pour remonter dans l'arborescence
    const goBack = () => {
        if (currentPath.length > 0) {
            navigateTo(currentPath.slice(0, -1));
        }
    };

    // Fonction pour afficher l'arborescence
    const renderTree = (data, path) => {
        return Object.keys(data).map((key) => {
            const isFile = typeof data[key] === 'string';
            return (
                <div key={key} className="fiche-item">
                    <button
                        className="fiche-button"
                        onClick={() => {
                            if (isFile) {
                                setSelectedPdf(data[key]);
                            } else {
                                navigateTo([...path, key]);
                            }
                        }}
                    >
                        {key} {isFile && '(PDF)'}
                    </button>
                </div>
            );
        });
    };

    if (!treeData) {
        return <div><Chargement /></div>;
    }

    const currentData = currentPath.reduce((acc, key) => acc[key], treeData);

    return (
        <div className={`fiche-container ${isMobile && selectedPdf ? 'hide-menu' : ''}`}>
            <div className="fiche-menu">
                {currentPath.length > 0 && (
                    <button className="fiche-button" onClick={goBack}>Retour</button>
                )}
                {renderTree(currentData, currentPath)}
            </div>
            <div className="fiche-content">
                {selectedPdf && <ShowPdf source={selectedPdf} />}
            </div>
        </div>
    );
};

export default Fiche;
