import React, { useState, useEffect } from 'react';
import { getFicheArbo, setArbo, setFiche, setQuestion, getQuestions, deleteQuestion } from '../routes/routes.js';
import '../css/View/Formateur.css';

const Formateur = () => {
    const [treeData, setTreeData] = useState({});
    const [selectedFolderPath, setSelectedFolderPath] = useState([]);
    const [currentPath, setCurrentPath] = useState([]);
    const [newFolderName, setNewFolderName] = useState('');
    const [selectedFiche, setSelectedFiche] = useState(null);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [questionType, setQuestionType] = useState('unique');
    const [answers, setAnswers] = useState(['']);
    const [difficulty, setDifficulty] = useState('novice');
    const [activeMenu, setActiveMenu] = useState('arborescence');
    const [selectedFilePath, setSelectedFilePath] = useState('');
    const [correctAnswers, setCorrectAnswers] = useState([]);

    useEffect(() => {
        const fetchTreeData = async () => {
            const data = await getFicheArbo();
            if (data && typeof data === 'object') {
                setTreeData(data);
            } else {
                console.error("Les données retournées ne sont pas un objet :", data);
            }
        };

        fetchTreeData();
    }, []);

    useEffect(() => {
        resetQuestionForm();
    }, [quizQuestions]);

    const resetQuestionForm = () => {
        setNewQuestion('');
        setQuestionType('unique');
        setAnswers(['']);
        setCorrectAnswers([]);
    };

    const handleAddFolder = async () => {
        if (newFolderName) {
            const path = [...currentPath, newFolderName].join('/');
            await setArbo('add', path);
            const updatedTree = await getFicheArbo();
            if (updatedTree && typeof updatedTree === 'object') {
                setTreeData(updatedTree);
            } else {
                console.error("Les données mises à jour ne sont pas un objet :", updatedTree);
            }
            setNewFolderName('');
        }
    };

    const handleDeleteFolder = async (folderPath) => {
        const pathParts = folderPath.split('/');
        const itemName = pathParts.pop();
        let currentLevel = treeData;

        for (const part of pathParts) {
            if (currentLevel[part] && typeof currentLevel[part] === 'object') {
                currentLevel = currentLevel[part];
            } else {
                console.error("Path not found in the tree data");
                return;
            }
        }

        const isFile = typeof currentLevel[itemName] === 'string';

        if (isFile) {
            await setFiche('delete', folderPath);
        } else if (typeof currentLevel[itemName] === 'object') {
            await setArbo('delete', folderPath);
        } else {
            console.error("The item to delete is neither a file nor a folder.");
            return;
        }

        const updatedTree = await getFicheArbo();
        if (updatedTree && typeof updatedTree === 'object') {
            setTreeData(updatedTree);
        } else {
            console.error("Les données mises à jour ne sont pas un objet :", updatedTree);
        }
    };

    const goBack = () => {
        if (currentPath.length > 0) {
            setCurrentPath(currentPath.slice(0, -1));
        }
    };

    const navigateTo = (path) => {
        setCurrentPath(path);
    };

    const handleAddFiche = async () => {
        if (selectedFiche && selectedFolderPath.length > 0) {
            await setFiche('add', selectedFolderPath.join('/'), selectedFiche);
            const updatedTree = await getFicheArbo();
            if (updatedTree && typeof updatedTree === 'object') {
                setTreeData(updatedTree);
            } else {
                console.error("Les données mises à jour ne sont pas un objet :", updatedTree);
            }
            setSelectedFiche(null);
        } else {
            console.error("No folder selected or no file chosen.");
        }
    };

    const handleAnswerChange = (index, value) => {
        const newAnswers = [...answers];
        newAnswers[index] = value;
        setAnswers(newAnswers);
    };

    const renderAnswersInput = () => {
        return questionType === 'qcm' ? (
            <>
                {answers.map((answer, index) => (
                    <div key={index} className="formateur-answer-item">
                        <input
                            type="checkbox"
                            className="formateur-checkbox"
                            onChange={(e) => handleCorrectAnswerChange(index, e.target.checked)}
                        />
                        <input
                            type="text"
                            className="formateur-input-text"
                            placeholder={`Réponse ${index + 1}`}
                            value={answer}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                        />
                    </div>
                ))}
                <button className="formateur-button" type="button" onClick={addAnswerField}>
                    Ajouter une réponse
                </button>
            </>
        ) : (
            <input
                type="text"
                className="formateur-input-text"
                placeholder="Réponse"
                value={answers[0]}
                onChange={(e) => handleAnswerChange(0, e.target.value)}
            />
        );
    };    

    const addQuestion = async () => {
        try {
            const questionData = {
                nomFiche: selectedFilePath,
                question: newQuestion,
                type: questionType,
                reponses: answers,
                correctAnswers: questionType === 'qcm' ? correctAnswers : [0],
            };
            const result = await setQuestion(questionData);  // Attend que setQuestion se termine
            console.log("Résultat de setQuestion:", result);  // Facultatif, utile pour déboguer
            console.log("Question ajoutée, mise à jour de la liste");
            await updateQuestionsList(selectedFilePath);
            console.log("Liste des questions mise à jour");
        } catch (error) {
            console.error("Erreur lors de l'ajout de la question :", error);
        }
    };       

    const handleCorrectAnswerChange = (index, isChecked) => {
        if (isChecked) {
            setCorrectAnswers([...correctAnswers, index]);
        } else {
            setCorrectAnswers(correctAnswers.filter(answerIndex => answerIndex !== index));
        }
    };

    const handleSelectFile = async (filePath) => {
        setSelectedFilePath(filePath);
        await updateQuestionsList(filePath);
    };

    const addAnswerField = () => {
        setAnswers([...answers, '']);
    };

    const renderAllFolders = (data, path = []) => {
        const folderKeys = Object.keys(data).filter(key => typeof data[key] === 'object');
        
        return folderKeys.flatMap(key => [
            <option key={path.concat(key).join('/')} value={path.concat(key).join('/')}>
                {path.concat(key).join(' / ')}
            </option>,
            ...renderAllFolders(data[key], path.concat(key))
        ]);
    };

    const renderFoldersWithPdf = (data, path = []) => {
        const folderKeys = Object.keys(data).filter(key => typeof data[key] === 'object');
        const fileKeys = Object.keys(data).filter(key => typeof data[key] === 'string' && data[key].endsWith('.pdf'));

        if (fileKeys.length > 0) {
            return (
                <option key={path.join(' / ') + '/' + fileKeys} value={path.join(' / ') + '/' + fileKeys}>
                    {path.join(' / ') + ' / ' + fileKeys}
                </option>
            );
        }

        return folderKeys.flatMap(key => renderFoldersWithPdf(data[key], path.concat(key)));
    };

    const updateQuestionsList = async (filePath) => {
        try {
            if(filePath !== ''){
                const questions = await getQuestions(filePath);
                setQuizQuestions(questions || []);
            } else {
                setQuizQuestions([]);
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la liste des questions :", error);
        }
    };

    const renderTree = (data, path) => {
        return Object.keys(data).map((key) => {
            const isFolder = typeof data[key] === 'object';
            return (
                <div key={key} className="formateur-item">
                    <button
                        className="formateur-button"
                        onClick={() => isFolder && navigateTo([...path, key])}
                    >
                        {key}
                    </button>
                    <button
                        className="formateur-button formateur-delete-button"
                        onClick={() => handleDeleteFolder([...path, key].join('/'))}
                    >
                        Supprimer
                    </button>
                </div>
            );
        });
    };

    const currentData = currentPath.reduce((acc, key) => acc[key], treeData);

    return (
        <div className="formateur-container">
            <div className="formateur-main-buttons">
                <button className="formateur-main-button" onClick={() => setActiveMenu('arborescence')}>Gérer l'arborescence</button>
                <button className="formateur-main-button" onClick={() => setActiveMenu('fiche')}>Ajouter une fiche</button>
                <button className="formateur-main-button" onClick={() => setActiveMenu('question')}>Questions</button>
            </div>

            {activeMenu === 'arborescence' && (
                <div className="formateur-menu-container">
                    <div className="formateur-arborescence-menu">
                        <h3 className="formateur-menu-title">Gérer l'arborescence</h3>
                        <input
                            type="text"
                            className="formateur-input-text"
                            placeholder="Nouveau dossier"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                        />
                        <button className="formateur-button" onClick={handleAddFolder}>Ajouter Dossier</button>
                        <div className="formateur-list">
                            {currentPath.length > 0 && (
                                <button className="formateur-button" onClick={goBack}>Retour</button>
                            )}
                            {renderTree(currentData, currentPath)}
                        </div>
                    </div>
                </div>
            )}

            {activeMenu === 'fiche' && (
                <div className="formateur-menu-container">
                    <div className="formateur-fiche-menu">
                        <h3 className="formateur-menu-title">Ajouter/Éditer une Fiche</h3>
                        <input
                            type="file"
                            className="formateur-input-file"
                            accept=".pdf"
                            onChange={(e) => setSelectedFiche(e.target.files[0])}
                        />
                        <select 
                            className="formateur-select" 
                            value={selectedFolderPath.join('/')} 
                            onChange={(e) => setSelectedFolderPath(e.target.value.split('/'))}
                        >
                            <option value="">Choisir un dossier</option>
                            {renderAllFolders(treeData)}
                        </select>
                        <select 
                            className="formateur-select" 
                            value={difficulty} 
                            onChange={(e) => setDifficulty(e.target.value)}
                        >
                            <option value="novice">Débutant</option>
                            <option value="intermédiaire">Intermédiaire</option>
                            <option value="avancé">Avancé</option>
                        </select>
                        <button className="formateur-button" onClick={handleAddFiche}>Enregistrer Fiche</button>
                    </div>
                </div>
            )}

            {activeMenu === 'question' && (
                <div className="formateur-menu-container">
                    <div className="formateur-question-menu">
                        <h3 className="formateur-menu-title">Gérer les questions</h3>
                        <select 
                            className="formateur-select"
                            onChange={(e) => handleSelectFile(e.target.value)}
                        >
                            <option value="">Choisir une fiche</option>
                            {renderFoldersWithPdf(treeData)}
                        </select>
                        {selectedFilePath && (
                            <div className="formateur-questions-container">
                                <h4>Questions associées</h4>
                                <ul className="formateur-questions-list">
                                    {quizQuestions.map((q, index) => (
                                        <li key={index} className="formateur-questions-list-item">
                                            <strong>{q.question}</strong>
                                            <button 
                                                className="formateur-button formateur-delete-button" 
                                                onClick={async () => {
                                                    await deleteQuestion(selectedFilePath, q.question);
                                                    await updateQuestionsList(selectedFilePath);
                                                }}
                                            >
                                                Supprimer
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {selectedFilePath && (
                            <div className="formateur-add-question">
                                <h4>Ajouter une question</h4>
                                <select 
                                    className="formateur-select"
                                    value={questionType}
                                    onChange={(e) => setQuestionType(e.target.value)}
                                >
                                    <option value="unique">Question à réponse unique</option>
                                    <option value="qcm">QCM</option>
                                </select>
                                <input
                                    type="text"
                                    className="formateur-input-text"
                                    placeholder="Nouvelle question"
                                    value={newQuestion}
                                    onChange={(e) => setNewQuestion(e.target.value)}
                                />
                                {renderAnswersInput()}
                                <button className="formateur-button" onClick={addQuestion}>Ajouter Question</button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Formateur;
