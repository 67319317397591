import React, { useState, useEffect } from 'react';
import { getAllQuestions } from '../routes/routes';
import Chargement from '../components/chargement';
import '../css/View/QuizQuotidien.css';

const QuizQuotidien = () => {
  const [quizIsStarted, setQuizIsStarted] = useState(false);
  const [tabFichesQuestions, setTabFichesQuestions] = useState([]);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [indexFiche, setIndexFiche] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState('');  
  const [feedback, setFeedback] = useState('');  
  const [shuffledAnswers, setShuffledAnswers] = useState([]); 
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questionsData = await getAllQuestions();
        setTabFichesQuestions(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);

  const normalizeString = (str) => {
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '');
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleCheckAnswer = () => {
    const correctAnswer = normalizeString(currentQuestion.reponse[0]);
    const normalizedUserAnswer = normalizeString(userAnswer);

    if (normalizedUserAnswer === correctAnswer) {
      setFeedback('Bonne réponse !');
    } else {
      setFeedback(`Mauvaise réponse. La bonne réponse était : ${currentQuestion.reponse[0]}`);
    }

    setIsAnswerSubmitted(true);  
    setUserAnswer('');  
  };

  const handleQCMAnswer = (answer) => {
    if (answer === currentQuestion.reponse[0]) {
      setFeedback('Bonne réponse !');
    } else {
      setFeedback(`Mauvaise réponse. La bonne réponse était : ${currentQuestion.reponse[0]}`);
    }

    setIsAnswerSubmitted(true);  
  };

  const getQuestion = () => {
    const fiche = tabFichesQuestions[indexFiche];
    const question = fiche.questions[indexQuestion];
    
    setCurrentQuestion(question);
    setFeedback('');  
    setIsAnswerSubmitted(false);  

    if (question.type === 'qcm') {
      setShuffledAnswers(shuffleArray([...question.reponse])); 
    }

    if (indexQuestion + 1 < fiche.questions.length) {
      setIndexQuestion(indexQuestion + 1);
    } else {
      setIndexQuestion(0);
      if (indexFiche + 1 < tabFichesQuestions.length) {
        setIndexFiche(indexFiche + 1);
      } else {
        setIndexFiche(0);
      }
    }
  };

  useEffect(() => {
    if (tabFichesQuestions.length > 0) {
      getQuestion();
    }
  }, [tabFichesQuestions]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (isAnswerSubmitted) {
        getQuestion();  
      } else if (currentQuestion.type === 'unique') {
        handleCheckAnswer();  
      }
    }
  };

  return (
    <div className="quiz-container">
      <h2 className="quiz-header">Quiz quotidien</h2>
      {quizIsStarted ? (
        <div>
          {currentQuestion ? (
            <div>
              <h3 className="quiz-question">{currentQuestion.question}</h3>
              {currentQuestion.type === 'unique' ? (
                <div>
                  <input
                    type="text"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="quiz-input"
                    placeholder="Votre réponse"
                  />
                  <button onClick={handleCheckAnswer} className="quiz-button">Valider</button>
                </div>
              ) : (
                <ul className="quiz-options">
                  {shuffledAnswers.map((reponse, index) => (
                    <li key={index}>
                      <button 
                        onClick={() => {
                          handleQCMAnswer(reponse);
                          handleKeyDown({ key: 'Enter' });
                        }}
                        className="quiz-button"
                      >
                        {reponse}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              {feedback && <p className="quiz-feedback">{feedback}</p>}
              <button onClick={getQuestion} className="quiz-button">Question Suivante</button>
            </div>
          ) : (
            <Chargement />
          )}
        </div>
      ) : (
        <div>
          <button onClick={() => setQuizIsStarted(true)} className="quiz-button">Commencer mon quiz</button>
        </div>
      )}
    </div>
  );
};

export default QuizQuotidien;
