import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { updateUser, getUser, disconectUser } from '../routes/routes';
import { getTokenFCM } from '../script/notif';
import { signOut } from 'firebase/auth';
import '../css/View/Profil.css';

const Profil = ({profilChange}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('');
  const [prenom, setPrenom] = useState('');
  const [sdis, setSdis] = useState('');
  const [statut, setStatut] = useState('');
  const [activationKey, setActivationKey] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userData = await getUser();
          if (userData) {
            // Initialiser les états avec les données récupérées
            setName(userData.name || '');
            setPrenom(userData.prenom || '');
            setSdis(userData.sdis || '');
            setStatut(userData.statut || '');
            setActivationKey(userData.activationKey || '');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des données utilisateur:', error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await updateUser({
          uid: user.uid,
          name,
          prenom,
          sdis,
          statut,
          activationKey,
        });
        console.log("Modifications sauvegardées : ", { name, prenom, sdis, statut, activationKey });
        setIsEditing(false);
        profilChange();
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil :", error);
    }
  };

  const handleLogout = async () => {
    try {
      const tokenFCM = await getTokenFCM();
  
      if (tokenFCM) {
        await disconectUser(tokenFCM);
      }
  
      await signOut(auth);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="profile-container">
      <h2>Mon Profil</h2>
      <div className="profile-info">
        {isEditing ? (
          <>
            <div className="profile-field">
              <label>Nom :</label>
              <input 
                type="text" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
              />
            </div>
            <div className="profile-field">
              <label>Prénom :</label>
              <input 
                type="text" 
                value={prenom} 
                onChange={(e) => setPrenom(e.target.value)} 
              />
            </div>
            <div className="profile-field">
              <label>SDIS :</label>
              <select value={sdis} onChange={(e) => setSdis(e.target.value)}>
                <option value="">Sélectionnez votre SDIS</option>
                <option value="Autre">Autre</option>
                <option value="01">SDIS 01</option>
                <option value="02">SDIS 02</option>
                <option value="03">SDIS 03</option>
                <option value="04">SDIS 04</option>
                <option value="05">SDIS 05</option>
                <option value="06">SDIS 06</option>
                <option value="07">SDIS 07</option>
                <option value="08">SDIS 08</option>
                <option value="09">SDIS 09</option>
                <option value="10">SDIS 10</option>
                <option value="11">SDIS 11</option>
                <option value="12">SDIS 12</option>
                <option value="13">SDIS 13</option>
                <option value="14">SDIS 14</option>
                <option value="15">SDIS 15</option>
                <option value="16">SDIS 16</option>
                <option value="17">SDIS 17</option>
                <option value="18">SDIS 18</option>
                <option value="19">SDIS 19</option>
                <option value="2A">SDIS 2A</option>
                <option value="2B">SDIS 2B</option>
                <option value="21">SDIS 21</option>
                <option value="22">SDIS 22</option>
                <option value="23">SDIS 23</option>
                <option value="24">SDIS 24</option>
                <option value="25">SDIS 25</option>
                <option value="26">SDIS 26</option>
                <option value="27">SDIS 27</option>
                <option value="28">SDIS 28</option>
                <option value="29">SDIS 29</option>
                <option value="30">SDIS 30</option>
                <option value="31">SDIS 31</option>
                <option value="32">SDIS 32</option>
                <option value="33">SDIS 33</option>
                <option value="34">SDIS 34</option>
                <option value="35">SDIS 35</option>
                <option value="36">SDIS 36</option>
                <option value="37">SDIS 37</option>
                <option value="38">SDIS 38</option>
                <option value="39">SDIS 39</option>
                <option value="40">SDIS 40</option>
                <option value="41">SDIS 41</option>
                <option value="42">SDIS 42</option>
                <option value="43">SDIS 43</option>
                <option value="44">SDIS 44</option>
                <option value="45">SDIS 45</option>
                <option value="46">SDIS 46</option>
                <option value="47">SDIS 47</option>
                <option value="48">SDIS 48</option>
                <option value="49">SDIS 49</option>
                <option value="50">SDIS 50</option>
                <option value="51">SDIS 51</option>
                <option value="52">SDIS 52</option>
                <option value="53">SDIS 53</option>
                <option value="54">SDIS 54</option>
                <option value="55">SDIS 55</option>
                <option value="56">SDIS 56</option>
                <option value="57">SDIS 57</option>
                <option value="58">SDIS 58</option>
                <option value="59">SDIS 59</option>
                <option value="60">SDIS 60</option>
                <option value="61">SDIS 61</option>
                <option value="62">SDIS 62</option>
                <option value="63">SDIS 63</option>
                <option value="64">SDIS 64</option>
                <option value="65">SDIS 65</option>
                <option value="66">SDIS 66</option>
                <option value="67">SDIS 67</option>
                <option value="68">SDIS 68</option>
                <option value="69">SDIS 69</option>
                <option value="70">SDIS 70</option>
                <option value="71">SDIS 71</option>
                <option value="72">SDIS 72</option>
                <option value="73">SDIS 73</option>
                <option value="74">SDIS 74</option>
                <option value="75">SDIS 75</option>
                <option value="76">SDIS 76</option>
                <option value="77">SDIS 77</option>
                <option value="78">SDIS 78</option>
                <option value="79">SDIS 79</option>
                <option value="80">SDIS 80</option>
                <option value="81">SDIS 81</option>
                <option value="82">SDIS 82</option>
                <option value="83">SDIS 83</option>
                <option value="84">SDIS 84</option>
                <option value="85">SDIS 85</option>
                <option value="86">SDIS 86</option>
                <option value="87">SDIS 87</option>
                <option value="88">SDIS 88</option>
                <option value="89">SDIS 89</option>
                <option value="90">SDIS 90</option>
                <option value="91">SDIS 91</option>
                <option value="92">SDIS 92</option>
                <option value="93">SDIS 93</option>
                <option value="94">SDIS 94</option>
                <option value="95">SDIS 95</option>
                <option value="976">SDIS 976</option>
                <option value="971">SDIS 971</option>
                <option value="973">SDIS 973</option>
                <option value="972">SDIS 972</option>
                <option value="974">SDIS 974</option>
                <option value="988">SDIS 988</option>
                <option value="975">SDIS 975</option>
                <option value="987">SDIS 987</option>
              </select>
            </div>
            <div className="profile-field">
              <label>Statut :</label>
              <select value={statut} onChange={(e) => setStatut(e.target.value)}>
                <option value="">Sélectionnez votre statut</option>
                <option value="SPV">SPV</option>
                <option value="SPP">SPP</option>
                <option value="Autre">Autre</option>
              </select>
            </div>
            <div className="profile-field">
              <label>Clé d'activation :</label>
              <input 
                type="text" 
                value={activationKey} 
                onChange={(e) => setActivationKey(e.target.value)} 
              />
            </div>
            <button onClick={handleSave} className="save-button">Sauvegarder</button>
          </>
        ) : (
          <>
            <p><strong>Nom :</strong> {name || 'Aucune information'}</p>
            <p><strong>Prénom :</strong> {prenom || 'Aucune information'}</p>
            <p><strong>SDIS :</strong> {sdis || 'Aucune information'}</p>
            <p><strong>Statut :</strong> {statut || 'Aucune information'}</p>
            <p><strong>Clé d'activation :</strong> {activationKey || 'Aucune clé saisie'}</p>
          </>
        )}
      </div>
      <div className="profile-actions">
        <button onClick={() => setIsEditing(!isEditing)} className="edit-button">
          {isEditing ? 'Annuler' : 'Éditer'}
        </button>
        <button onClick={handleLogout} className="logout-button">Déconnexion</button>
      </div>
    </div>
  );
};

export default Profil;
