import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getTokenFCM } from '../script/notif';
import { addNewUser, updateUser } from '../routes/routes';
import '../css/Component/auth.css';

const Auth = ({isSignedUp, isSignIn}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [connectionVue, setConnectionVue] = useState(true);
  const [passwordForget, setPasswordForget] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');

  const handleSignUp = async () => {
    try {
      if (email !== '' && password !== '' && password === confirmPassword) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const uid = user.uid;
        Notification.requestPermission().then(async (permission) => { // Ajout de 'async' ici
          if (permission === 'granted') {
            const tokenFCM = await getTokenFCM();
            if (tokenFCM) {
              const response = await addNewUser(email, tokenFCM, uid);
              if (response.message === "User created successfully") {
                isSignedUp();
              }
            }
          } else {
            const response = await addNewUser(email, '', uid);
            if (response.message === "User created successfully") {
              isSignedUp();
            }
          }
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSignIn = async () => {
    try {
      if(email !== '' && password !== ''){
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        if(user){
          getTokenFCM().then((tokenFCM) => {
            if (tokenFCM) {
              updateUser({
                uid: user.uid,
                tokensFCM: tokenFCM
              });
            }
          });
          isSignIn();
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePasswordReset = async () => {
    try {
      if (recoveryEmail !== '') {
        await sendPasswordResetEmail(auth, recoveryEmail);
        alert('Un email de réinitialisation a été envoyé.');
        setPasswordForget(false);
      }
    } catch (error) {
      console.error(error.message);
      alert('Erreur lors de l\'envoi de l\'email de réinitialisation.');
    }
  };

  // Ajoutez cette fonction pour gérer l'appui sur la touche "Entrée"
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSignIn();  // Appeler la fonction handleSignIn lors de l'appui sur "Entrée"
    }
  };

  return (
    <div className='mainAuth'>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
      />
      {connectionVue ? (
        <div className='connectionDiv'>
          <h1>{passwordForget ? 'Mot de passe oublié' : 'Se connecter'}</h1>
          <p>
            Pas encore inscrit ?{' '}
            <span 
              style={{ textDecoration: 'underline', cursor: 'pointer', color: 'white' }} 
              onClick={() => setConnectionVue(false)}
            > 
              Créez votre compte ici.
            </span>
          </p>
          <br />
          {passwordForget ? (
            <div className="input-container">
              <label htmlFor="inputRecoveryEmail">EMAIL</label>
              <input
                type="email"
                placeholder=""
                value={recoveryEmail}
                onChange={(e) => setRecoveryEmail(e.target.value)}
                id='inputRecoveryEmail'
                className="custom-input"
              />
              <button onClick={handlePasswordReset}>Envoyer</button>
              <button onClick={() => setPasswordForget(false)}>Annuler</button>
            </div>
          ) : (
            <>
              <div className="input-container">
                <label htmlFor="inputEmailConnection">EMAIL</label>
                <input
                  type="email"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id='inputEmailConnection'
                  className="custom-input"
                  onKeyDown={handleKeyDown} // Ajout du gestionnaire d'événements ici
                />
              </div>
              <div className="input-container">
                <label htmlFor="inputPasswordConnection">MOT DE PASSE</label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id='inputPasswordConnection'
                  className="custom-input"
                  onKeyDown={handleKeyDown} // Ajout du gestionnaire d'événements ici
                />
                <i 
                  className={`fas ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"} eye-icon`}
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)} 
                ></i>
                <span 
                  style={{ textDecoration: 'underline', cursor: 'pointer', color: 'white' }} 
                  onClick={() => setPasswordForget(true)}
                > 
                  Mot de passe oublié ?
                </span>
              </div>
              <button onClick={handleSignIn}>Se connecter</button>
            </>
          )}
        </div>
      ) : (
        <div className='connectionDiv'>
          <h1>S'inscrire</h1>
          <p>
            Vous avez déjà un compte ?{' '}
            <span 
              style={{ textDecoration: 'underline', cursor: 'pointer', color: 'white' }} 
              onClick={() => setConnectionVue(true)}
            > 
              Connectez-vous ici.
            </span>
          </p>
          <br />
          <div className="input-container">
            <label htmlFor="inputEmailInscription">EMAIL</label>
            <input
              type="email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id='inputEmailInscription'
              className="custom-input"
            />
          </div>
          <div className="input-container">
            <label htmlFor="inputPasswordInscription">MOT DE PASSE</label>
            <input
              type={isPasswordVisible ? "text" : "password"}
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id='inputPasswordInscription'
              className="custom-input"
            />
            <i 
              className={`fas ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"} eye-icon-inscription`}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)} 
            ></i>
          </div>
          <div className="input-container">
            <label htmlFor="inputConfirmPasswordInscription">CONFIRMER MOT DE PASSE</label>
            <input
              type={isPasswordVisible ? "text" : "password"}
              placeholder=""
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id='inputConfirmPasswordInscription'
              className="custom-input"
            />
            <i 
              className={`fas ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"} eye-icon-inscription`}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)} 
            ></i>
          </div>
          <button onClick={handleSignUp}>S'inscrire</button>
        </div>
      )}
    </div>
  );
};

export default Auth;