// apiUtils.js
import api from '../axiosConfig'
import { auth } from '../firebaseConfig';

export async function getAuthHeaders() {
  const user = auth.currentUser;
  
  if (!user) throw new Error('User not authenticated');

  const token = await user.getIdToken();
  
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
}

export async function postWithAuth(url, data) {
  try {
    const headers = await getAuthHeaders();
    return await api.post(url, data, { headers });
  } catch (error) {
    console.error(`Error posting to ${url}:`, error);
    throw error;
  }
}

export async function getWithAuth(url, params) {
  try {
    const headers = await getAuthHeaders();
    return await api.get(url, { headers, params });
  } catch (error) {
    console.error(`Error fetching from ${url}:`, error);
    throw error;
  }
}
