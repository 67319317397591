import { postWithAuth, getWithAuth } from './apiUtils';
import api from '../axiosConfig'
import { auth } from '../firebaseConfig';

// User

export async function addNewUser(email, tokenFCM, uid) {
  try {
    const response = await postWithAuth('/user/create', { email, tokenFCM, uid });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updateUser(updateData) {
  await postWithAuth('/user/update', updateData);
}

export async function disconectUser(tokenFCM) {
  const user = auth.currentUser;
  if (user) {
    await postWithAuth('/user/deleteToken', { uid: user.uid, tokenFCM });
  }
}

export async function getUser(){
  const user = auth.currentUser;
  try {
    if (user) {
      const response = await getWithAuth(`/user/getUser/${user.uid}`);
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function isAdmin() {
  try {
    const response = await getWithAuth(`/user/isAdmin/${auth.currentUser.uid}`);
    return response.data.isAdmin;
  } catch (error) {
    return false;
  }
}

export async function isFormateur() {
  try {
    const response = await getWithAuth(`/user/isFormateur/${auth.currentUser.uid}`);
    return response.data.isFormateur;
  } catch (error) {
    return false;
  }
}

export async function hasKey() {
  try {
    const response = await getWithAuth(`/user/hasKey/${auth.currentUser.uid}`);
    return response.data.hasKey;
  } catch (error) {
    return false;
  }
}

// Fiche

export async function getFicheArbo() {
  try {
    const response = await getWithAuth('/fiche/arbo');
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function setArbo(action, path) {
  const uid = auth.currentUser.uid;
  await postWithAuth('/fiche/arbo/set', { action, path, uid });
}

export async function setFiche(action, path, file) {
  try {
    const user = auth.currentUser;
    if (user) {
      const uid = auth.currentUser.uid;
      const token = await user.getIdToken();
      const formData = new FormData();
      formData.append('action', action);
      formData.append('path', path);
      formData.append('file', file);
      formData.append('uid', uid);

      await api.post('/fiche/file/set', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  } catch (error) {
    console.error('Error setting fiche:', error);
  }
}

// Questions

export async function setQuestion(questionData) {
  try {
    const uid = auth.currentUser.uid;
    questionData = { ...questionData, uid };
    const response = await postWithAuth('/question/set', questionData);
    return response.data;  // Si le serveur retourne des données intéressantes
  } catch (error) {
    console.error('Erreur lors de l\'ajout de la question:', error);
    throw error;  // Propager l'erreur pour la gérer plus haut
  }
}

export async function getQuestions(nomFiche) {
  try {
    const response = await getWithAuth('/question/get', { nomFiche });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function deleteQuestion(nomFiche, question) {
  const uid = auth.currentUser.uid;
  await postWithAuth('/question/delete', { nomFiche, question, uid });
}

export async function getAllQuestions(){
  const response = await getWithAuth('/question/get/all');
  return response.data;
}

// Cle
export async function createKeys(nombreCles) {
  const uid = auth.currentUser.uid;
  try {
      const response = await postWithAuth('/cle/create', { nombreCles, uid });
      return response.data;
  } catch (error) {
      console.error('Error creating keys:', error);
      throw error;
  }
}