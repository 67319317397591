import React from 'react';
import '../css/View/Connexion.css';
import Auth from '../components/auth';
import logo1000 from '../img/icon-wb-1000x1000.png';

const Connexion = ({ onSignUp, onSignIn }) => {
  return (
    <div className="main">
      <div className='LogoContainer'>
        <img src={logo1000} alt='Logo' className='logo_1000' />
        <p className="neonText">FORMASAPP</p>
        <p className='descriptionText'>L'application d'autoformation et de maintien des acquis des sapeurs-pompiers.</p>
      </div>
      <div className='AuthContainer'>
        <Auth isSignedUp={onSignUp} isSignIn={onSignIn} />
      </div>
    </div>
  );
}

export default Connexion;
