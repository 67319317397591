import React, { useState, useEffect } from 'react';
import '../css/View/App.css';
import Connexion from './Connexion';
import Menu from '../view/Menu.js';
import { auth } from '../firebaseConfig';
import { getUser } from '../routes/routes.js';
import Chargement from '../components/chargement.js';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSignUp = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleSignIn = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  }

  // Enregistrement du Service Worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .catch((err) => {
          console.error('Service Worker registration failed:', err);
        });
    }
  }, []);

  // Vérification de l'état de connexion de l'utilisateur
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if(!user){
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Vérifier si l'utilisateur est authentifié via le stockage local
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div className="loading"><Chargement /></div>;
  }

  return (
    <div className="App">
      {isAuthenticated ? (
        <Menu />
      ) : (
        <Connexion onSignUp={handleSignUp} onSignIn={handleSignIn} />
      )}
    </div>
  );
}

export default App;
