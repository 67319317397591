import React, { useState } from 'react';
import { createKeys } from '../routes/routes'; // Assurez-vous que le chemin vers createKeys est correct

const Admin = () => {
    const [numberOfKeys, setNumberOfKeys] = useState(1);
    const [createdKeys, setCreatedKeys] = useState([]);

    const handleCreateKeys = async () => {
        try {
            const data = await createKeys(numberOfKeys);
            setCreatedKeys(data.keys);  // Mettre à jour l'état avec les clés générées
        } catch (error) {
            console.log(error);
        }
    };

    const handleDownloadKeys = () => {
        const element = document.createElement("a");
        const file = new Blob([createdKeys.join('\n')], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "keys.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (
        <div>
            <h1>Créer des clés</h1>
            <input
                type="number"
                value={numberOfKeys}
                onChange={(e) => setNumberOfKeys(e.target.value)}
                min="1"
                placeholder="Nombre de clés"
            />
            <button onClick={handleCreateKeys}>
                Créer {numberOfKeys} clé(s)
            </button>
            
            {createdKeys.length > 0 && (
                <div>
                    <h2>Clés créées:</h2>
                    <ul>
                        {createdKeys.map((key, index) => (
                            <li key={index}>{key}</li>
                        ))}
                    </ul>
                    <button onClick={handleDownloadKeys}>
                        Télécharger clés
                    </button>
                </div>
            )}
        </div>
    );
};

export default Admin;
