import { getToken, onMessage } from "firebase/messaging";
import { messaging }from '../firebaseConfig'

// Return FCM Token
// @Type : String
export function getTokenFCM() {
    return getToken(messaging, { vapidKey: 'BAI66h8zq2Xa14iQe3CpzXbyQ5yCAWcWN8RGeUUZA0t6Ii08A80ciwPlaMY9Jz29BVe8ZXbxlAM9f8nUl89Dv58' })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          console.log('No registration token available.');
          return null;
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token: ', err);
        return null;
      });
  }

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Personnaliser l'affichage des notifications
  });